<template>
  <AppBar class="BMap-box">
    <div class="BMap-root">
      <el-dropdown @visible-change="(flag) => { showListFlag = flag }" trigger="click" placement="bottom-start" @command="stationActive" :hide-on-click="false">
        <button :class="!showListFlag ? 'show-all el-dropdown-link' : 'showList show-all el-dropdown-link'" @click="showAll">
          <img :src="require('@/assets/logo.png')"/>加油站列表<i :class='!showListFlag ? "el-icon-caret-bottom" : "el-icon-caret-top"'></i>
        </button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in totalLocations"
            :key="item.ali_order_id"
            :command="item.ali_order_id"
            @click.stop
          >
            <div class="station-name">
              {{ index + 1 }}.{{ item.competitorAlisaName }}
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <baidu-map
        :style="{ width: map.width, height: map.height }"
        class="map"
        ak="你的百度地图秘钥"
        :zoom="map.zoom"
        :maxZoom="map.maxZoom"
        :center="{ lng: map.center.lng, lat: map.center.lat }"
        @ready="handler"
        :scroll-wheel-zoom="true"
        :map-click="false"
      >
        <!-- 信息窗体 -->
        <bm-info-window
          :position="stationMsgPosition"
          :closeOnClick="true"
          :show="stationMsg != null"
          @close="stationMsgWindowCloseTwo"
          @clickclose="stationMsgWindowClose"
        >
          <div v-if="stationMsg" :class="stationMsg.ali_order_id ? 'text-list':''">
            <div v-if="stationMsg.ali_order_id">
              <p>名称: {{ stationMsg.competitorAlisaName || stationMsg.name }}</p>
              <p>地址: {{ stationMsg.address }}</p>
              <p>联系电话: {{ stationMsg.mobile }}</p>
              <div>洗车服务: {{ stationMsg.carWash || "无" }}</div>
              <div>吸尘服务: {{ stationMsg.dustCollector || "无" }}</div>
            </div>
            <div v-if="!stationMsg.ali_order_id" class="competitor-list competitor-list1">
              <p>名称: {{ stationMsg.competitorAlisaName || stationMsg.name }}</p>
              <p>地址: {{ stationMsg.address }}</p>
              <p>经纬度: {{ stationMsg.longitude }}, {{stationMsg.latitude}}</p>
              <p>车流密度: {{stationMsg.trafficDensity}}</p>
              <p>油品号数量: {{ stationMsg.gasItems }}</p>
              <p>充气吸尘设备: {{ stationMsg.dustCollector }}</p>
              <p>前庭服务人员数量: {{stationMsg.waiterNumbers}}</p>
              <p>可同时加油车位数量: {{stationMsg.carLocationQty}}</p>
              <p>竞争站进出口便捷性: {{ stationMsg.outConvenience }}</p>
              <p>竞争站进出站通道独立性: {{ stationMsg.roadUnique }}</p>
            </div>
            <div v-if="!stationMsg.ali_order_id" class="competitor-list">
              <p>品牌: {{ stationMsg.brand }}</p>
              <p>洗车服务: {{stationMsg.carWash || "无"}}</p>
              <p>运营模式: {{stationMsg.businessModel}}</p>
              <p>支付便捷性: {{ stationMsg.outsitePayMethod }}</p>
              <p>竞争站方位: {{ stationMsg.position }}</p>
              <p>竞争站形象: {{ stationMsg.impression }}</p>
              <p>竞争站进深: {{ stationMsg.enterDistance }}</p>
              <p>竞争站可见度: {{ stationMsg.visibility }}</p>
              <p>是否有便利店: {{stationMsg.hasShop}}</p>
            </div>
          </div>
          <table v-if="stationMsg && stationMsg.ali_order_id">
            <tr class="tr_text">
              <th>油品</th>
              <th>价格</th>
            </tr>
            <tr>
              <!-- 油品 -->
              <td>#92</td>
              <td>
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem92
                    ? stationMsg.localGusItem.localPriceItem92
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <!-- 油品 -->
              <td>#95</td>
              <td>
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem95
                    ? stationMsg.localGusItem.localPriceItem95
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <!-- 油品 -->
              <td>#98</td>
              <td>
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem98
                    ? stationMsg.localGusItem.localPriceItem98
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <!-- 油品 -->
              <td>#0</td>
              <td>
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItemDiesel
                    ? stationMsg.localGusItem.localPriceItemDiesel
                    : "-"
                }}
              </td>
            </tr>
          </table>
          <table v-else-if="stationMsg" class="compete-table">
            <tr class="tr_text">
              <th>油品</th>
              <th>我方站价格</th>
              <th>竞争站价格</th>
              <th>价格走势</th>
            </tr>
            <tr v-if="stationMsg.localGusItem">
              <!-- 油品 -->
              <td>#92</td>
              <!--  判断油的价格是否为空 我方站价格 -->
              <td>
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem92
                    ? stationMsg.localGusItem.localPriceItem92
                    : "-"
                }}
              </td>
              <!-- 判断油的价格是否为空 竞争站价格 -->
              <td :class="stationMsg.localGusItem && stationMsg.localGusItem.priceItem92?'competitivePrice':''">
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.priceItem92
                    ? stationMsg.localGusItem.priceItem92
                    : "-"
                }}
              </td>
              <!-- 判断油的价格是否为空 判断双方差价是否超过0.3超过显示红色否则显示绿色 -->
              <td
                class="zousi"
                :class="
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem92 &&
                  stationMsg.localGusItem.priceItem92
                    ? stationMsg.localGusItem.localPriceItem92 -
                        stationMsg.localGusItem.priceItem92 >= 0.3
                      ? 'color-red'
                      : 'color-green'
                    : ''
                "
              >
                <!--  判断油的价格是否为空 判断我方价格高还是竞争方价格高我方价格高显示下箭头 -->
                <div
                  v-show="
                    stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem92 &&
                      stationMsg.localGusItem.priceItem92
                  "
                  :class="
                    stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem92 -
                      stationMsg.localGusItem.priceItem92 <=
                    0
                      ? 'up'
                      : 'down'
                  "
                >
                  <div></div>
                  <div></div>
                </div>
                <!--判断双方价格是否为空  显示双方差价 -->
                <div class="difference">
                  {{
                    stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem92 &&
                    stationMsg.localGusItem.priceItem92
                      ? Number(
                          stationMsg.localGusItem.localPriceItem92 -
                            stationMsg.localGusItem.priceItem92
                        ).toFixed(2)
                      : "-"
                  }}
                </div>
              </td>
            </tr>
            <tr v-else>
              <td>#92</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>

            <tr v-if="stationMsg.localGusItem">
              <!-- 油品 -->
              <td>#95</td>
              <!--  判断油的价格是否为空 我方站价格 -->
              <td>
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem95
                    ? stationMsg.localGusItem.localPriceItem95
                    : "-"
                }}
              </td>
              <!-- 判断油的价格是否为空 竞争站价格 -->
              <td :class="stationMsg.localGusItem && stationMsg.localGusItem.priceItem95?'competitivePrice':''">
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.priceItem95
                    ? stationMsg.localGusItem.priceItem95
                    : "-"
                }}
              </td>
              <!-- 判断油的价格是否为空 判断双方差价是否超过0.3超过显示红色否则显示绿色 -->
              <td
                class="zousi"
                :class="
                  stationMsg.localGusItem &&
                  stationMsg.localGusItem.localPriceItem95 &&
                  stationMsg.localGusItem.priceItem95
                    ? stationMsg.localGusItem.localPriceItem95 -
                          stationMsg.localGusItem.priceItem95 >= 0.3
                      ? 'color-red'
                      : 'color-green'
                    : ''
                "
              >
                <!--  判断油的价格是否为空 判断我方价格高还是竞争方价格高我方价格高显示下箭头 -->
                <div
                  v-show="
                    stationMsg.localGusItem &&
                    stationMsg.localGusItem.localPriceItem95 &&
                    stationMsg.localGusItem.priceItem95
                  "
                  :class="
                    stationMsg.localGusItem.localPriceItem95 -
                      stationMsg.localGusItem.priceItem95 <=
                    0
                      ? 'up'
                      : 'down'
                  "
                >
                  <div></div>
                  <div></div>
                </div>
                <!--判断双方价格是否为空  显示双方差价 -->
                <div class="difference">
                  {{
                    stationMsg.localGusItem &&
                    stationMsg.localGusItem.localPriceItem95 &&
                    stationMsg.localGusItem.priceItem95
                      ? Number(
                          stationMsg.localGusItem.localPriceItem95 -
                            stationMsg.localGusItem.priceItem95
                        ).toFixed(2)
                      : "-"
                  }}
                </div>
              </td>
            </tr>
            <tr v-else>
              <td>#95</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>

            <tr v-if="stationMsg.localGusItem">
              <!-- 油品 -->
              <td>#98</td>
              <!--  判断油的价格是否为空 我方站价格 -->
              <td>
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem98
                    ? stationMsg.localGusItem.localPriceItem98
                    : "-"
                }}
              </td>
              <!-- 判断油的价格是否为空 竞争站价格 -->
              <td :class="stationMsg.localGusItem && stationMsg.localGusItem.priceItem98?'competitivePrice':''">
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.priceItem98
                    ? stationMsg.localGusItem.priceItem98
                    : "-"
                }}
              </td>
              <!-- 判断油的价格是否为空 判断双方差价是否超过0.3超过显示红色否则显示绿色 -->
              <td
                class="zousi"
                :class="
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem98 &&
                  stationMsg.localGusItem.priceItem98
                    ? stationMsg.localGusItem.localPriceItem98 -
                        stationMsg.localGusItem.priceItem98 >= 0.3
                      ? 'color-red'
                      : 'color-green'
                    : ''
                "
              >
                <!--  判断油的价格是否为空 判断我方价格高还是竞争方价格高我方价格高显示下箭头 -->
                <div
                  v-show="
                    stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem98 &&
                      stationMsg.localGusItem.priceItem98
                  "
                  :class="
                    stationMsg.localGusItem.localPriceItem98 -
                      stationMsg.localGusItem.priceItem98 <=
                    0
                      ? 'up'
                      : 'down'
                  "
                >
                  <div></div>
                  <div></div>
                </div>
                <!--判断双方价格是否为空  显示双方差价 -->
                <div class="difference">
                  {{
                    stationMsg.localGusItem && stationMsg.localGusItem.localPriceItem98 &&
                    stationMsg.localGusItem.priceItem98
                      ? Number(
                          stationMsg.localGusItem.localPriceItem98 -
                            stationMsg.localGusItem.priceItem98
                        ).toFixed(2)
                      : "-"
                  }}
                </div>
              </td>
            </tr>
            <tr v-else>
              <td>#98</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr v-if="stationMsg.localGusItem">
              <!-- 油品 -->
              <td>#0</td>
              <!--  判断油的价格是否为空 我方站价格 -->
              <td>
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItemDiesel
                    ? stationMsg.localGusItem.localPriceItemDiesel
                    : "-"
                }}
              </td>
              <!-- 判断油的价格是否为空 竞争站价格 -->
              <td :class="stationMsg.localGusItem && stationMsg.localGusItem.priceItemDiesel?'competitivePrice':''">
                {{
                  stationMsg.localGusItem && stationMsg.localGusItem.priceItemDiesel
                    ? stationMsg.localGusItem.priceItemDiesel
                    : "-"
                }}
              </td>
              <!-- 判断油的价格是否为空 判断双方差价是否超过0.3超过显示红色否则显示绿色 -->
              <td
                class="zousi"
                :class="
                  stationMsg.localGusItem && stationMsg.localGusItem.localPriceItemDiesel &&
                  stationMsg.localGusItem.priceItemDiesel
                    ? stationMsg.localGusItem.localPriceItemDiesel -
                        stationMsg.localGusItem.priceItemDiesel >= 0.3
                      ? 'color-red'
                      : 'color-green'
                    : ''
                "
              >
                <!--  判断油的价格是否为空 判断我方价格高还是竞争方价格高我方价格高显示下箭头 -->
                <div
                  v-show="
                    stationMsg.localGusItem && stationMsg.localGusItem.localPriceItemDiesel &&
                      stationMsg.localGusItem.priceItemDiesel
                  "
                  :class="
                    stationMsg.localGusItem.localPriceItemDiesel -
                      stationMsg.localGusItem.priceItemDiesel <=
                    0
                      ? 'up'
                      : 'down'
                  "
                >
                  <div></div>
                  <div></div>
                </div>
                <!--判断双方价格是否为空  显示双方差价 -->
                <div class="difference">
                  {{
                    stationMsg.localGusItem && stationMsg.localGusItem.localPriceItemDiesel &&
                    stationMsg.localGusItem.priceItemDiesel
                      ? Number(
                          stationMsg.localGusItem.localPriceItemDiesel -
                            stationMsg.localGusItem.priceItemDiesel
                        ).toFixed(2)
                      : "-"
                  }}
                </div>
              </td>
            </tr>
            <tr v-else>
              <td>#0</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </table>
        </bm-info-window>
        <!-- 道达尔站 -->
        <bm-overlay
          pane="labelPane"
          class="daoerda"
          :title="item.name"
          :class="{ sample: true, dn: item.show == 0 }"
          @draw="draw"
          v-for="(item, index) in totalLocations"
          :key="item.aliCode"
          :data-lng="item.longitude"
          :data-lat="item.latitude"
        >
          <div @click.stop="clickShow($event, item)" :data-index="index">
            <img :src="totalUrl" />
            <span></span>
          </div>
        </bm-overlay>
        <!-- 竞争站 -->
        <bm-overlay
          pane="labelPane"
          :class="{ sample: true, dn: item.show == 0 }"
          @draw="draw"
          v-for="(item, index) in competeLocations"
          :key="index"
          :data-lng="item.longitude"
          :data-lat="item.latitude"
        >
          <div @click.stop="clickLabel($event, item)" :data-index="index">
            <img
              :src="item.priceLevel == 'GREEN' ? imgGUrl : imgRUrl"
              v-if="item.priceLevel"
            />
            <img :src="require('@/assets/m1.png')" v-else />
            <img :src="require('@/assets/station.png')" class="station-icon" />
            <span></span>
          </div>
        </bm-overlay>

        <!-- <bm-overlay @draw="msgDraw" pane="labelPane" class="msg" v-if="isShow">
          <div>
            <div class="close-div">
              <div class="title">{{ serviceInfo.name }}</div>
              <span @click="closeMsg">X</span>
            </div>

            <table cellspacing="0" border="1px">
              <tr class="tr_text">
                <th>油品</th>
                <th>我方站价格</th>
                <th>竞争站价格</th>
                <th>价格走势</th>
              </tr>
              <tr>
                油品
                <td>#92</td>
                 判断油的价格是否为空 我方站价格
                <td>
                  {{
                    competitorStoreGusItem.localPriceItem92
                      ? competitorStoreGusItem.localPriceItem92
                      : "-"
                  }}
                </td>
                判断油的价格是否为空 竞争站价格
                <td class="competitivePrice">
                  {{
                    competitorStoreGusItem.priceItem92
                      ? competitorStoreGusItem.priceItem92
                      : "-"
                  }}
                </td>
                判断油的价格是否为空 判断双方差价是否超过0.3超过显示红色否则显示绿色
                <td
                  class="zousi"
                  :class="
                    competitorStoreGusItem.localPriceItem92 &&
                    competitorStoreGusItem.priceItem92
                      ? Math.abs(
                          competitorStoreGusItem.localPriceItem92 -
                            competitorStoreGusItem.priceItem92
                        ) > 0.3
                        ? 'color-red'
                        : 'color-green'
                      : ''
                  "
                >
                   判断油的价格是否为空 判断我方价格高还是竞争方价格高我方价格高显示下箭头
                  <div
                    v-show="
                      competitorStoreGusItem.localPriceItem92 &&
                        competitorStoreGusItem.priceItem92
                    "
                    :class="
                      competitorStoreGusItem.localPriceItem92 -
                        competitorStoreGusItem.priceItem92 <=
                      0
                        ? 'up'
                        : 'down'
                    "
                  >
                    <div></div>
                    <div></div>
                  </div>
                  判断双方价格是否为空  显示双方差价
                  <div class="difference">
                    {{
                      competitorStoreGusItem.localPriceItem92 &&
                      competitorStoreGusItem.priceItem92
                        ? Number(
                            competitorStoreGusItem.localPriceItem92 -
                              competitorStoreGusItem.priceItem92
                          ).toFixed(2)
                        : "-"
                    }}
                  </div>
                </td>
              </tr>

              <tr>
                油品
                <td>#95</td>
                 判断油的价格是否为空 我方站价格
                <td>
                  {{
                    competitorStoreGusItem.localPriceItem95
                      ? competitorStoreGusItem.localPriceItem95
                      : "-"
                  }}
                </td>
                判断油的价格是否为空 竞争站价格
                <td class="competitivePrice">
                  {{
                    competitorStoreGusItem.priceItem95
                      ? competitorStoreGusItem.priceItem95
                      : "-"
                  }}
                </td>
                判断油的价格是否为空 判断双方差价是否超过0.3超过显示红色否则显示绿色
                <td
                  class="zousi"
                  :class="
                    competitorStoreGusItem.localPriceItem95 &&
                    competitorStoreGusItem.priceItem95
                      ? Math.abs(
                          competitorStoreGusItem.localPriceItem95 -
                            competitorStoreGusItem.priceItem95
                        ) > 0.3
                        ? 'color-red'
                        : 'color-green'
                      : ''
                  "
                >
                   判断油的价格是否为空 判断我方价格高还是竞争方价格高我方价格高显示下箭头
                  <div
                    v-show="
                      competitorStoreGusItem.localPriceItem95 &&
                        competitorStoreGusItem.priceItem95
                    "
                    :class="
                      competitorStoreGusItem.localPriceItem95 -
                        competitorStoreGusItem.priceItem95 <=
                      0
                        ? 'up'
                        : 'down'
                    "
                  >
                    <div></div>
                    <div></div>
                  </div>
                  判断双方价格是否为空  显示双方差价
                  <div class="difference">
                    {{
                      competitorStoreGusItem.localPriceItem95 &&
                      competitorStoreGusItem.priceItem95
                        ? Number(
                            competitorStoreGusItem.localPriceItem95 -
                              competitorStoreGusItem.priceItem95
                          ).toFixed(2)
                        : "-"
                    }}
                  </div>
                </td>
              </tr>

              <tr>
                油品
                <td>#98</td>
                 判断油的价格是否为空 我方站价格
                <td>
                  {{
                    competitorStoreGusItem.localPriceItem98
                      ? competitorStoreGusItem.localPriceItem98
                      : "-"
                  }}
                </td>
                判断油的价格是否为空 竞争站价格
                <td class="competitivePrice">
                  {{
                    competitorStoreGusItem.priceItem98
                      ? competitorStoreGusItem.priceItem98
                      : "-"
                  }}
                </td>
                判断油的价格是否为空 判断双方差价是否超过0.3超过显示红色否则显示绿色
                <td
                  class="zousi"
                  :class="
                    competitorStoreGusItem.localPriceItem98 &&
                    competitorStoreGusItem.priceItem98
                      ? Math.abs(
                          competitorStoreGusItem.localPriceItem98 -
                            competitorStoreGusItem.priceItem98
                        ) > 0.3
                        ? 'color-red'
                        : 'color-green'
                      : ''
                  "
                >
                   判断油的价格是否为空 判断我方价格高还是竞争方价格高我方价格高显示下箭头
                  <div
                    v-show="
                      competitorStoreGusItem.localPriceItem98 &&
                        competitorStoreGusItem.priceItem98
                    "
                    :class="
                      competitorStoreGusItem.localPriceItem98 -
                        competitorStoreGusItem.priceItem98 <=
                      0
                        ? 'up'
                        : 'down'
                    "
                  >
                    <div></div>
                    <div></div>
                  </div>
                  判断双方价格是否为空  显示双方差价
                  <div class="difference">
                    {{
                      competitorStoreGusItem.localPriceItem98 &&
                      competitorStoreGusItem.priceItem98
                        ? Number(
                            competitorStoreGusItem.localPriceItem98 -
                              competitorStoreGusItem.priceItem98
                          ).toFixed(2)
                        : "-"
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                油品
                <td>#0</td>
                 判断油的价格是否为空 我方站价格
                <td>
                  {{
                    competitorStoreGusItem.localPriceItemDiesel
                      ? competitorStoreGusItem.localPriceItemDiesel
                      : "-"
                  }}
                </td>
                判断油的价格是否为空 竞争站价格
                <td class="competitivePrice">
                  {{
                    competitorStoreGusItem.priceItemDiesel
                      ? competitorStoreGusItem.priceItemDiesel
                      : "-"
                  }}
                </td>
                判断油的价格是否为空 判断双方差价是否超过0.3超过显示红色否则显示绿色
                <td
                  class="zousi"
                  :class="
                    competitorStoreGusItem.localPriceItemDiesel &&
                    competitorStoreGusItem.priceItemDiesel
                      ? Math.abs(
                          competitorStoreGusItem.localPriceItemDiesel -
                            competitorStoreGusItem.priceItemDiesel
                        ) > 0.3
                        ? 'color-red'
                        : 'color-green'
                      : ''
                  "
                >
                   判断油的价格是否为空 判断我方价格高还是竞争方价格高我方价格高显示下箭头
                  <div
                    v-show="
                      competitorStoreGusItem.localPriceItemDiesel &&
                        competitorStoreGusItem.priceItemDiesel
                    "
                    :class="
                      competitorStoreGusItem.localPriceItemDiesel -
                        competitorStoreGusItem.priceItemDiesel <=
                      0
                        ? 'up'
                        : 'down'
                    "
                  >
                    <div></div>
                    <div></div>
                  </div>
                  判断双方价格是否为空  显示双方差价
                  <div class="difference">
                    {{
                      competitorStoreGusItem.localPriceItemDiesel &&
                      competitorStoreGusItem.priceItemDiesel
                        ? Number(
                            competitorStoreGusItem.localPriceItemDiesel -
                              competitorStoreGusItem.priceItemDiesel
                          ).toFixed(2)
                        : "-"
                    }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </bm-overlay> -->
        <bm-InfoWindow></bm-InfoWindow>
      </baidu-map>
      <!-- >提示信息 -->
      <div class="message-div" v-show="hint">
        <div class="close-btn" @click="hintClose">X</div>
        <span>暂无油价信息</span>
      </div>
    </div>
  </AppBar>
</template>

<script>
import AppBar from "@/components/AppBar";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker";
import BmMarkerClusterer from "vue-baidu-map/components/extra/MarkerClusterer";
import BmOverlay from "vue-baidu-map/components/overlays/Overlay.vue";
import BmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow";
export default {
  data: function() {
    return {
      //提示信息
      hint: false,
      //uuid
      uuid: "6515f589866a11e994a2506b4b416ad4",
      //道尔达logo
      totalUrl: require("@/assets/logo.png"),
      //道达尔油站
      totalLocations: [],
      //竞争加油站logo红
      imgRUrl: require("@/assets/m2.png"),
      //竞争加油站logo绿
      imgGUrl: require("@/assets/m0.png"),

      //竞争油站
      competeLocations: [],
      //消息框坐标
      msgCoord: {
        x: 0,
        y: 0
      },
      //竞争站服务信息
      serviceInfo: {},
      //消息框显示或隐藏
      isShow: false,
      //竞争站油价
      competitorStoreGusItem: {},
      map: {
        //地图中心
        center: { lng: 114.311754, lat: 30.598604 }, //'武汉市政府',
        zoom: 12,
        width: "100%",
        height: "100%",
        maxZoom: 16
      },
      stationMsg: null,
      stationMsgPosition: {},
      showListFlag: false,
      clearFlag: true
    };
  },
  components: {
    BaiduMap,
    BmMarker,
    BmMarkerClusterer,
    BmOverlay,
    BmInfoWindow,
    AppBar
  },
  mounted: function() {
    this.init();
  },
  methods: {
    //关闭提示
    hintClose: function() {
      this.hint = false;
    },
    //展示全部
    showAll: function() {
      //获得所有的道达尔站
      this.getLocalStation(this.uuid);
      //获取所有的竞争站
      // this.getCompeteLocations(this.uuid, null);
      this.isShow = false;
      this.showListFlag = !this.showListFlag;
    },
    //初始化
    init: function() {
      //获得所有的道达尔站
      this.getLocalStation(this.uuid);
      // this.getTotalLocations();
      //获取所有的竞争站
      this.getCompeteLocations(this.uuid, null);
    },
    //点击道尔达油站
    clickShow(e, item) {
      // this.$nextTick(() => {
      //   this.stationMsg = null;
      //   this.clearFlag = false;
      // });
      this.stationActive(item.ali_order_id);
    },
    // 选中油站
    stationActive(id) {
      this.map.zoom = 16;
      this.stationMsg = null;
      this.clearFlag = false;
      this.totalLocations.forEach(item => {
        if (item.ali_order_id === id) {
          this.$nextTick(() => {
            this.stationMsg = item;
            this.stationMsgPosition = {
              lng: this.stationMsg.longitude,
              lat: this.stationMsg.latitude
            };
            this.map.center.lng = this.stationMsg.longitude;
            this.map.center.lat = this.stationMsg.latitude;
          });
        }
      });
    },
    // 关闭信息弹窗
    stationMsgWindowClose() {
      if (this.clearFlag) {
        this.$nextTick(() => {
          this.stationMsg = null;
        });
      }
      this.clearFlag = true;
    },
    stationMsgWindowCloseTwo() {
      if (this.clearFlag) {
        this.$nextTick(() => {
          this.stationMsg = null;
        });
      }
    },
    //读取油价详情
    getCompetitorStoreGusItem: async function(code) {
      const { data: res } = await this.$http.get(
        "/totalsmart-crm-server/rest/competitor/getStationGusItemPrice/" + code
      );
      if (res.code) {
        return alert("读取油价详情失败");
      }

      if (res.data) {
        this.stationMsg.localGusItem = res.data;
      } else {
        // this.hint = true;
        setTimeout(() => {
          this.hint = false;
        }, 1000);
      }
    },
    //读取单个竞争站信息
    getMessage: async function name(code) {
      const { data: res } = await this.$http.get(
        "/totalsmart-crm-server/rest/competitor/getStationByCode/" + code
      );
      if (res.code) {
        return alert("读取单个竞争站数据失败");
      }
      this.serviceInfo = res.data;
    },
    //读取道尔达油站
    getLocalStation: async function() {
      const { data: res } = await this.$http.get(
        "/totalsmart-crm-server/rest/competitor/getLocalStationByCompanyId/" +
          this.uuid
      );
      if (res.code) {
        return alert("读取道达尔加油站数据失败");
      }
      this.totalLocations = res.data;
    },
    //读取取竞争站
    getCompeteLocations: async function(uuid, belongStoreUuid) {
      let query = {};
      belongStoreUuid ? (query.belongStoreUuid = belongStoreUuid) : "";
      uuid ? (query.companyUuid = uuid) : "";

      const { data: res } = await this.$http.post(
        "/totalsmart-crm-server/rest/competitor/queryStation",
        query
      );
      if (res.code) {
        return alert("读取竞争站数据失败");
      }
      this.competeLocations = res.data;
      this.isShow = false;
    },
    //读取道尔达油站
    getTotalLocations: async function() {
      const { data: res } = await this.$http.get(
        "/totalsmart-crm-server/rest/mdata/store/getAllStoresByOrgUuid/" +
          this.uuid
      );
      if (res.code) {
        return alert("读取道达尔加油站数据失败");
      }

      this.totalLocations = res.data;
    },
    closeMsg: function() {
      this.isShow = false;
    },
    draw({ el, BMap, map }) {
      this.map.zoom = map.zoom;
      let lng = el.dataset.lng;
      let lat = el.dataset.lat;
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
      el.style.left = pixel.x - 32 + "px";
      el.style.top = pixel.y - 50 + "px";
    },
    //消息框绘制
    msgDraw({ el, BMap, map }) {
      const pixel = map.pointToOverlayPixel(
        new BMap.Point(this.msgCoord.x, this.msgCoord.y)
      );
      el.style.left = pixel.x + "px";
      el.style.top = pixel.y + "px";
    },

    //点单个竞争站
    clickLabel(e, item) {
      // this.msgCoord = {
      //   x: Number(item.longitude),
      //   y: Number(item.latitude)
      // };
      this.isShow = false;
      this.labelstationActive(item);
      // await this.getCompetitorStoreGusItem(item.code);
      this.getMessage(item.code);
    },
    // 选中油站
    async labelstationActive(item) {
      this.map.zoom = 16;
      this.stationMsg = null;
      this.clearFlag = false;
      const { data: res } = await this.$http.get(
        "/totalsmart-crm-server/rest/competitor/getStationGusItemPrice/" + item.code
      );
      if (res.code) {
        return alert("读取油价详情失败");
      }
      if (res.data) {
        this.stationMsg = item;
        this.stationMsg.localGusItem = res.data;
        this.map.center.lng = item.longitude;
        this.map.center.lat = item.latitude;
        this.stationMsgPosition = {
          lng: item.longitude,
          lat: item.latitude
        };
      } else {
        this.stationMsg = item;
        this.map.center.lng = item.longitude;
        this.map.center.lat = item.latitude;
        this.stationMsgPosition = {
          lng: item.longitude,
          lat: item.latitude
        };
        this.stationMsg.localGusItem = null;
      }
    },
    handler: function() {}
  }
};
</script>
<style lang="less">
.BMap-box {
  .el-main {
    padding: 0;
  }
}
.dn {
  display: none;
}
.el-dropdown {
  position: absolute;
  left: 9px;
  top: 10px;
  width: 300px;
  height: 76px;
}
.el-dropdown-menu {
  height: 500px;
  overflow: auto;
  width: 300px;
  margin-left: 10px;
  margin-top: 1px solid #ccc;
  border-radius: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  li {
    padding-left: 20px;
    height: 72px;
    font-size: 18px;
    line-height: 72px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  li:last-of-type {
    border-bottom: none;
  }
}
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #656565;
  background-color: rgba(0, 133, 255, 0.1);
}
/*滚动条样式*/
.el-dropdown-menu::-webkit-scrollbar {
  width: 7px;
  /*height: 4px;*/
}
.el-dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 14px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.1);
}
.el-dropdown-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 1);
  border-radius: 0;
  background: rgba(236, 236, 236, 0.4);
}
.station-name,
.station-address,
.station-service {
  display: flex;
  justify-content: flex-start;
  div {
    min-width: 40px;
    line-height: 26px;
  }
  div:last-child {
    word-wrap: break-word;
    word-break: break-all;
    word-wrap: break-word;
  }
}
.message-div {
  position: absolute;
  left: 50%;
  top: 50%;
  background: #fff;
  width: 200px;
  height: 100px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);

  span {
    display: block;
    line-height: 100px;
    text-align: center;
    height: 100px;
    font-size: 20px;
  }
  .close-btn {
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;
    padding: 5px;
  }
}
.BMap-root {
  width: 100%;
  height: 100%;
  position: relative;
  .station-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    left: 50%;
    top: 50%;
    transform: translate(-6px, -6px);
  }
  .BMap_bubble_content {
    line-height: 22px;
    padding: 0 50px;
    box-sizing: border-box;
    width: auto;
    max-width: 1180px;
    min-width: 760px;
    & > div {
      display: flex;
      table {
        order: 0;
        width: 340px;
        height: 150px;
        align-self: center;
        color: #656565;
        border-collapse: separate;
        border-spacing: 2px;
        th,
        td {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          border: 1px solid #ECECEC;
          box-sizing: border-box;
          text-align: center;
        }
        .tr_text {
          th {
            background-color: #ECECEC;
          }
        }
      }
      .compete-table {
        width: 255px;
        th,
        td {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          border: 1px solid #ECECEC;
          box-sizing: border-box;
          text-align: center;
        }
        .tr_text {
          th {
            background-color: #ECECEC;
          }
        }
      }
      & > div {
        display: flex;
      }
      .text-list {
        flex-wrap: wrap;
        order: 1;
        font-size: 14px;
        position: relative;
        align-self: center;
        line-height: 35px;
        margin-left: 50px;
        box-sizing: border-box;
      }
      .competitor-list {
        position: relative;
        box-sizing: border-box;
        line-height: 25px;
        font-size: 14px;
        &::after {
          position: absolute;
          top: 0;
          right: -10px;
          height: 102%;
          width: 1px;
          content: "";
          background-color: #ECECEC;
        }
      }
      .competitor-list1 {
        margin-right: 20px;
      }
    }
  }
  table {
    margin: 10px auto 0;

    td {
      text-align: center;
      font-size: 14px;
    }
    th {
      font-size: 12px;
      height: 20px;
    }
    .competitivePrice {
      color: #0082ff;
    }
    .zousi {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    div:nth-of-type(2) {
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }
  .color-red {
    color: red;
    --color: red;
  }
  .color-green {
    color: #0082ff;
    --color: #0082ff;
  }
  .up {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div:nth-of-type(2) {
      border-left: 2px solid var(--color);
      height: 16px;
    }
    div:nth-of-type(1) {
      border: 5px solid transparent;
      border-bottom-color: var(--color);
    }
  }
  .down {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div:nth-of-type(1) {
      border-left: 2px solid var(--color);
      height: 16px;
    }
    div:nth-of-type(2) {
      border: 5px solid transparent;
      border-top-color: var(--color);
    }
  }
  .difference {
    padding: 5px;
    width: 100%;
    text-align: center;
  }
  .show-all {
    width: 215px;
    height: 78px;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 999;
    padding: 5px;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
    cursor: pointer;
    background: #fff;
    color: #0082ff;
    font-size: 18px;
    display: flex;
    align-items: center;
    & > img {
      width: 40px;
      height: 40px;
      margin: 0 20px;
    }
    & > i {
      margin-left: 10px;
    }
  }
  .showList {
    width: 300px;
    height: 78px;
    border-radius: 5px 5px 0 0;
  }
  
}
.BMap_Marker.BMap_noprint {
  display: none !important;
}
.tr_text {
  font-size: 12px;
}
.sample {
  color: #fff;
  text-align: center;
  position: absolute;

  div {
    cursor: pointer;
    position: relative;

    img {
      width: 55px;
      display: block;
    }
    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      user-select: none;
    }
    .anchorBL {
      display: none;
    }
  }
}
.msg::after {
  content: "";
  border: 10px solid transparent;
  border-top-color: #fff;
  position: absolute;
  left: 50%;
  margin-left: -5px;
  bottom: -10px;
  transform: rotate(135deg);
}
.msg {
  width: 238px;
  padding: 10px 0;
  // height: 200px;
  z-index: 10;
  position: absolute;
  transform: translate(-55%, -135%);
  color: #000;
  background: #fff;
  border-radius: 10px;

  .close-div {
    display: flex;
    justify-content: space-between;

    .title {
      text-align: center;
      height: 25px;
      line-height: 25px;
      width: 100%;
      font-size: 18px;
      font-weight: bold;
    }
    span {
      padding: 5px;
      padding-bottom: 0;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  
  .BMap_cpyCtrl {
    span {
      display: none;
    }
  }
}

</style>
